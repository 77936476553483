.header {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 28px 0;
  z-index: 3;
  background: linear-gradient(180deg, #1d71b8 0%, rgba(29, 113, 184, 0) 100%);
  @media (max-width: 767px) {
    background: linear-gradient(180deg, #1d71b8 0%, rgba(29, 114, 184, 0.7) 60%, rgba(29, 113, 184, 0) 100%);
    padding: 16px 0;
    position: fixed;
  }
  &__container {
    display: flex;
    align-items: center;
  }

  .logo_link {
    transition: 0.3s;
    @media (max-width: 767px) {
      margin: 0 auto;
      padding-left: 32px;
    }
  }

  .main {
    &__menu {
      margin-left: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      gap: 4px 16px;
      padding-left: 16px;
      @media (max-width: 767px) {
        display: flex;
        position: fixed;
        flex-direction: column;
        align-items: flex-start;
        left: -205px;
        top: 0;
        z-index: 9;
        background: #2173ba;
        padding: 32px;
        height: 100%;
        width: 200px;
        justify-content: flex-start;
        gap: 16px;
        transition: 0.3s;
        &.active {
          left: 0;
        }
      }
      &_btn {
        color: #ffffff;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: 0.42px;
        text-transform: uppercase;
        cursor: pointer;
      }
      &_mobile {
        display: none;
        position: fixed;
        left: 24px;
        top: 28px;
        transition: 0.3s;
        z-index: 10;
        @media (max-width: 767px) {
          display: block;
        }
        img {
          width: 20px;
          height: 20px;
        }
        &.active {
          left: 220px;
        }
      }
    }
    &__social-list {
      margin-left: 16px;
      display: flex;
      @media (max-width: 767px) {
        display: none;
      }
    }
    &__btn-in {
      margin-left: 16px;
      display: flex;
      align-items: center;
      max-width: 230px;
      min-height: 40px;
      padding-right: 18px;
      text-decoration: none;
      color: #ffffff;
      border: 1px solid #ffffff;
      background-color: transparent;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.35s;
      @media (max-width: 767px) {
        padding-right: 0;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    &__icon-in {
      margin-left: 6px;
    }
    &__name-person {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}
