.subtop {
  margin-top: -40px;
  margin-bottom: 50px;
  &__container {
    display: flex;
    position: relative;
    &_wrap {
      display: flex;
      position: relative;
      width: 100%;
      @media (max-width: 1023px) {
        flex-wrap: wrap;
      }
    }
  }
  &__text {
    width: 40%;
    min-width: 40%;
    margin-right: 32px;
    @media (max-width: 1023px) {
      width: 100%;
      margin-right: 0;
    }
    &_title {
      color: #da1f3d;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 32px;
      @media (max-width: 767px) {
        font-size: 24px;
        letter-spacing: 1.2px;
      }
    }
    &_descr {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      p {
        margin-bottom: 16px;
      }
    }
  }
  &__video {
    position: absolute;
    right: 0;
    bottom: -12px;
    width: 58%;
    @media (max-width: 1023px) {
      position: relative;
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }
    video {
      width: 100%;
      display: block;
    }
  }
}
