.bckgr {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  &__img {
    background-image: url('./../assets/images/bckgr.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
}
