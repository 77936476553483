.top {
    .mobile {
        display: none !important;
        @media (max-width: 1023px) {
            display: block !important;
        }
    }
    &__bg {
        background-image: url('./../assets/images/top.jpg');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: calc(100vw / 2.337);
        @media (max-width: 1023px) {
            background-position: 28% bottom;
        }
    }
    &__container {
        padding-top: 100px;
        display: flex;
        justify-content: space-between;
    }
    &__left-block {
        margin-top: 80px;
        max-width: 1160px;
        @media (max-width: 1023px) {
            margin-top: 24px;
        }
        @media (max-width: 767px) {
            margin-top: 16px;
        }
    }
    &__right-block {
        margin-top: 80px;
        @media (max-width: 1023px) {
            display: none;
            margin-top: 0;
            margin-bottom: 180px;
        }
    }
    &__title {
        color: #ffffff;
        font-size: 72px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        letter-spacing: 5.04px;
        text-transform: uppercase;
        @media (max-width: 1023px) {
            font-size: 42px;
        }
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
    &__caption {
        color: #ffffff;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        margin-top: 32px;
        @media (max-width: 1023px) {
            margin-top: 24px;
            font-size: 16px;
        }
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
    &__text {
        color: #ffffff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 32px;
        max-width: 670px;
        @media (max-width: 1023px) {
            margin-top: 24px;
            font-size: 16px;
        }
    }
    &__btn-flex {
        margin-top: 32px;
        margin-bottom: 10vw;
        display: flex;
        flex-wrap: wrap;
        @media (max-width: 1440px) {
            margin-bottom: 20vw;
        }
        @media (max-width: 1023px) {
            margin-top: 24px;
            margin-bottom: 0;
        }
        a {
            margin-right: 32px;
            @media (max-width: 1023px) {
                margin-bottom: 24px;
                display: block;
                width: 200px;
            }
        }
    }
}
