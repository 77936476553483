.breadscrumb {
  display: flex;
  align-items: center;
  img {
    margin-top: -1px;
  }
  .crumb {
    &_item {
      margin-left: 10px;
      padding-right: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      color: #00c2ff;
      text-decoration: none;
      position: relative;
      &::before {
        content: '/';
        position: absolute;
        right: -3px;
        top: 1px;
      }
      &:last-child {
        &::before {
          display: none;
        }
      }
    }
    &_first {
      color: #ffffff;
    }
  }
}
