.profit {
  margin-top: 42px;
  margin-bottom: 80px;
  &__title {
    color: #da1f3d;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 48px;
    @media (max-width: 767px) {
      font-size: 24px;
      letter-spacing: 1.2px;
    }
  }
  &__wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }
  &__left {
    max-width: calc(50% - 16px);
    gap: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1023px) {
      max-width: 100%;
    }

  }
  &__right {
    max-width: calc(50% - 16px);
    gap: 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1023px) {
      max-width: 100%;
    }
    & .profit__item {
      @media (max-width: 1270px) {
        height: auto;
      }
    }
  }
  &__item {
    display: flex;
    width: 100%;
    border: 1px solid #2173ba;
    border-radius: 2px;
    position: relative;
    padding: 24px;
    padding-left: 0;
    align-items: center;
    height: fit-content;
    @media (max-width: 1023px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
    }
    &_text {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 135%;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      @media (max-width: 1695px) {
        font-size: 14px;
      }
      @media (max-width: 1400px) {
        font-size: 13px;
      }
      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }
  }
}
