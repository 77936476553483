.authorization,
.vote,
.sendvote {
    position: relative;
    z-index: 1;
    margin-bottom: 500px;
    @media (max-width: 1024px) {
        margin-bottom: 600px;
    }
    @media (max-width: 767px) {
        margin-bottom: 0;
    }
    & .download {
      & a {
      color: white;
      font-size: 14px;
    }
    }
    &__vk {
      border: 1px solid white;
      max-width: 400px;
      max-height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      border-radius: 12px;
      margin: 32px 0;
      gap: 0 10px;
      cursor: pointer;
      text-transform: uppercase;
      height: 100%;
      color: white;
      padding: 18px 34px;
    }
    &__container {
        max-width: 836px;
        margin: 140px auto;
        background: #2173ba;
        padding: 40px;
        @media (max-width: 767px) {
            margin: 74px auto;
            padding: 24px;
            padding-bottom: 500px;
        }
    }
    &__title {
        color: #ffffff;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
        margin-bottom: 32px;
        max-width: 500px;
        @media (max-width: 767px) {
            margin-bottom: 24px;
            font-size: 30px;
        }
    }
    &__desc {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 32px;
        @media (max-width: 767px) {
            margin-top: 24px;
        }
    }
    &__input {
        &-group {
            display: flex;
            align-items: flex-start;
            margin-top: 32px;
            margin-bottom: 32px;

            gap: 32px;
            flex-wrap: wrap;
            position: relative;
            @media (max-width: 767px) {
                margin-top: 24px;
                gap: 24px;
                flex-direction: column;
            }
            &.captcha {
                .authorization__input-block {
                    width: 100%;
                }
            }
            &.phone {
                .authorization__input-block {
                    width: calc((100% / 3 * 2) - (32px / 2));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .authorization__input-button {
                    width: calc((100% / 3) - (32px / 2));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
            &.sms {
                .authorization__input-block {
                    width: calc(40% - (32px * 2 / 3));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .authorization__repeat-btn {
                    width: calc(30% - (32px * 2 / 3));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .authorization__input-button {
                    width: calc(30% - (32px * 2 / 3));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
            &.double {
                &.checkboxes {
                    @media (max-width: 767px) {
                        gap: 0;
                    }
                }
                .authorization__input-block {
                    width: calc((100% / 2) - (32px / 2));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .sendvote__input-block,
                .sendvote__checkbox-block {
                    width: calc((100% / 2) - (32px / 2));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
            &.triple {
                &.checkboxes {
                    @media (max-width: 767px) {
                        gap: 0;
                    }
                }
                .authorization__input-block {
                    width: calc((100% / 3) - (32px * 2 / 3));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
                .sendvote__input-block,
                .sendvote__checkbox-block {
                    width: calc((100% / 3) - (32px * 2 / 3));
                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
            &.download {
                margin-top: 16px;
            }
        }
        &-block {
            position: relative;
            @media (max-width: 767px) {
                width: 100%;
            }
            &.w-full {
                width: 100%;
            }
            label {
                color: #ffffff;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                opacity: 0.5;
                margin-bottom: 10px;
                display: block;
            }
            input,
            .input,
            textarea {
                border-radius: 2px;
                border: 1px solid #fff;
                background: rgba(0, 0, 0, 0.5);
                padding: 14px 16px;
                color: #ffffff;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                width: 100%;
                &::placeholder {
                    color: #ffffff;
                    opacity: 0.5;
                }
                .react-date-picker__inputGroup {
                    padding: 0;
                    margin-top: 2px;
                }
                &.datepicker {
                    padding: 13px 16px;
                }
                input {
                    padding: 0;
                    border: 0;
                    background: transparent;
                    max-width: 100%;
                    height: auto;
                    &.react-date-picker__inputGroup__input--hasLeadingZero {
                        margin-left: -0.54em;
                        padding-left: calc(1px + 0.54em);
                    }
                }
                .react-date-picker__wrapper {
                    border: 0;
                }
            }
            input,
            .input {
                height: 50px;
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }
                &:placeholder {
                    overflow: hidden;
                    white-space: nowrap;
                }
                &:placeholder-shown {
                    text-overflow: ellipsis;
                }
            }
            textarea {
                resize: none;
                height: 100px;
            }
            .select {
                input,
                .input {
                    height: auto;
                }
            }
        }
        &-button {
            &.to-right {
                display: flex;
                justify-content: flex-end;
                .authorization__btn-submit {
                    max-width: 260px;
                    @media (max-width: 767px) {
                        margin-top: 26px;
                        max-width: 260px;
                    }
                }
                .sendvote__btn-submit {
                    max-width: 260px;
                    @media (max-width: 767px) {
                        margin-top: 26px;
                        max-width: 260px;
                    }
                }
            }
        }
    }
    &__checkbox {
        &-block {
            position: relative;
            @media (max-width: 767px) {
                width: 100%;
            }
            label {
                display: flex;
                align-items: center;
                span {
                    display: block;
                    color: #ffffff;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    position: relative;
                    padding-left: 40px;
                    a {
                        color: #00c2ff;
                        text-decoration: none;
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        left: 10px;
                        top: 0;
                        height: 16px;
                        width: 16px;
                        border: 2px solid #ffffff;
                        display: inline-block;
                        border-radius: 2px;
                    }
                }
                input {
                    visibility: hidden;
                    display: block;
                    height: 0;
                    width: 0;
                    position: absolute;
                    overflow: hidden;
                }
                [type='checkbox']:checked+span::before {
                    background-color: #ffffff;
                    background-image: url('./../../assets/images/check.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
    }
    &__btn-submit {
        margin-top: 26px;
        padding: 16px;
        width: 100%;
        border-radius: 2px;
        position: relative;
        @media (max-width: 767px) {
            margin-top: 0;
            max-width: 220px;
        }
    }
    &__repeat-btn {
        margin-top: 26px;
        padding: 7px 0;
        width: 100%;
        position: relative;
        border-radius: 2px;
        border: 1px solid #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        @media (max-width: 767px) {
            margin-top: 0;
        }
        button {
            width: 100%;
            height: 34px;
            background: transparent;
            border: 0;
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }
    &__error {
        color: #ff0000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        opacity: 0.7;
        position: absolute;
        bottom: -18px;
        left: 2px;
        white-space: nowrap;
    }
    hr {
        border: 0;
        margin: 32px 0;
        padding: 0;
        width: 100%;
        height: 1px;
        background: #00c2ff;
    }
}

.vote {
    &__container {
        max-width: 1000px;
    }
    &__title {
        margin-top: 32px;
    }
    &__descr {
        color: #ffffff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 32px;
    }
    &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: stretch;
    }
    &__card {
        position: relative;
        width: calc((100% / 3) - (16px * 2 / 3));
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 24px 50px 24px 24px;
        background-image: url('./../../assets/images/content/vote/nominations.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        @media (max-width: 767px) {
            width: 100%;
        }
        &_dot {
            position: absolute;
            width: 70px;
            height: 70px;
            right: -13px;
            top: -15px;
        }
        &_title {
            overflow: hidden;
            color: #ffffff;
            text-overflow: ellipsis;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-bottom: 24px;
        }
        &_descr {
            overflow: hidden;
            color: #ffffff;
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            height: 150px;
            display: -webkit-box;
            -webkit-line-clamp: 9;
            -webkit-box-orient: vertical;
            @media (max-width: 767px) {
                height: auto;
                overflow: unset;
            }
        }
        &_btn {
            background: transparent;
            border-radius: 2px;
            border: 1px solid #fff;
            height: 40px;
            width: 160px;
            margin-top: 24px;
            padding: 0;
            &:hover {
                background: #ffffff;
                color: #40a5db;
            }
        }
        &_voted {
            background: transparent;
            border-radius: 2px;
            border: 1px solid transparent;
            height: 40px;
            width: 100%;
            margin-top: 24px;
            padding: 0;
            color: #ffffff;
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__ext {
        margin-top: 32px;
        color: #ffffff;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.sendvote {
    &__title {
        margin-top: 32px;
    }
    &__descr {
        color: #ffffff;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 32px;
    }
    &__subdescr {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 32px;
        margin-bottom: 16px;
    }
    &__text {
        color: #ffffff;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
    &__download {
        display: flex;
        text-decoration: none;
        img {
            width: 16px;
            margin-right: 10px;
        }
        p {
            color: #ffffff;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
    }
    &__input {
        &-block {
            &.file {
                .file-text {
                    &__wrap {
                        position: relative;
                        input[type='file'] {
                            position: relative;
                            z-index: 3;
                            opacity: 0;
                            cursor: pointer;
                        }
                    }
                    &__pseudo {
                        position: absolute;
                        right: 10px;
                        top: 16px;
                        width: 19px;
                        height: 19px;
                        z-index: 1;
                    }
                    &__value {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 0;
                        border-radius: 2px;
                        border: 1px solid #fff;
                        background: rgba(0, 0, 0, 0.5);
                        padding: 14px 16px;
                        color: #889cae;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 120%;
                        width: 100%;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        &.active {
                            color: #ffffff;
                        }
                    }
                    &__multiply {
                        display: flex;
                        flex-wrap: wrap;
                        &_item {
                            display: flex;
                            align-items: center;
                            border-radius: 16px;
                            background: #ffffff;
                            padding: 2px 8px;
                            margin-right: 10px;
                            margin-top: 10px;
                        }
                        &_text {
                            color: #000000;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                        }
                        &_image {
                            margin-left: 4px;
                            width: 10px;
                            height: 10px;
                            cursor: pointer;
                        }
                    }
                }
                &.multiply {
                    input[type='file'] {
                        height: 120px;
                    }
                }
            }
            &.links_multi {
                input {
                    padding-right: 50px;
                }
                .add-btn {
                    position: absolute;
                    right: 5px;
                    top: 32px;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 8;
                    cursor: pointer;
                    border-radius: 2px;
                    color: #ffffff;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 100%;
                    user-select: none;
                }
                .file-text {
                    &__multiply {
                        display: flex;
                        flex-wrap: wrap;
                        &_item {
                            display: flex;
                            align-items: center;
                            border-radius: 16px;
                            background: #ffffff;
                            padding: 2px 8px;
                            margin-right: 10px;
                            margin-top: 10px;
                        }
                        &_text {
                            color: #000000;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                        }
                        &_image {
                            margin-left: 4px;
                            width: 10px;
                            height: 10px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    &__imageprev {
        position: relative;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            object-fit: contain;
            border-radius: 2px;
            border: 1px solid #fff;
            background: rgba(0, 0, 0, 0.5);
        }
        input {
            position: relative;
            z-index: 0;
            height: 268px;
            opacity: 0;
            cursor: pointer;
        }
    }
    &__loadimg {
        position: absolute;
        width: 141px;
        height: 40px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
        img {
            background: none;
            border: none;
        }
    }
    &__loadico {
        position: absolute;
        width: 141px;
        height: 40px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        img {
            background: none;
        }
    }
    &__input-subblock {
        width: 100% !important;
        margin-bottom: 32px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__simpletext {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
}

.profile {
    position: relative;
    z-index: 1;
    &__title {
        color: #000;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.4px;
        text-transform: uppercase;
    }
    &__container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    &__left {
        width: calc((100% / 3 * 2) - 5px);
    }
    &__right {
        width: calc((100% / 3) - 5px);
    }
    &__bottom {
        width: 100%;
        margin-top: 32px;
    }
    &__details {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        margin-top: 32px;
        width: 100%;
        &_field {
            width: calc((100% / 3) - (32px * 2 / 3));
            display: flex;
            flex-direction: column;
            @media (max-width: 767px) {
                width: 100%;
            }
            &.full {
                width: 100%;
            }
        }
    }
    &__label {
        color: #2173ba;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 10px;
        opacity: 0.5;
    }
    &__value {
        color: #000;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__logout {
        border-radius: 2px;
        border: 1px solid var(--Red, #da1f3d);
        width: 180px;
        cursor: pointer;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #da1f3d;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        img {
            margin-right: 10px;
        }
    }
    &__vote {
        width: 100%;
        margin-top: 80px;
        margin-bottom: 80px;
        background-image: url('./../../assets/images/content/votes/votes_bck.png');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        padding-top: 48px;
        padding-bottom: 48px;
        @media (max-width: 767px) {
            background-image: url('./../../assets/images/content/votes/votes_bck_m.png');
        }
        .title {
            color: #ffffff;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.4px;
            text-transform: uppercase;
        }
        &_wrap {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 32px;
        }
    }
    &__voting {
        width: 100%;
        margin-top: 80px;
        margin-bottom: 80px;
        background-image: url('./../../assets/images/content/votes/voting_bck.png');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        padding-top: 48px;
        padding-bottom: 48px;
        @media (max-width: 767px) {
            background-image: url('./../../assets/images/content/votes/voting_bck_m.png');
        }
        .title {
            color: #ffffff;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.4px;
            text-transform: uppercase;
        }
        &_wrap {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 32px;
        }
    }
}

.news {
    position: relative;
    z-index: 1;
    margin-bottom: 300px;
    @media (max-width: 1024px) {
        margin-bottom: 500px;
    }
    @media (max-width: 767px) {
        margin-bottom: 0;
    }
    &__container {
        width: 100%;
        @media (max-width: 767px) {
            padding-bottom: 500px;
        }
    }
    &__list {
        margin-top: 32px;
        margin-bottom: 32px;
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
        justify-content: flex-start;
        @media (max-width: 767px) {
            flex-direction: column;
        }
        .votes__item {
            width: calc((100% / 4) - (32px * 3 / 4));
            @media (max-width: 1023px) {
                width: calc((100% / 2) - (32px / 2));
            }
            @media (max-width: 767px) {
                width: 100%;
            }
        }
    }
    &__empty {
        max-width: 560px;
        text-align: center;
        color: #000000;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin: 0 auto;
        display: flex;
        align-items: center;
        border: 1px solid #2173ba;
        border-radius: 2px;
        padding: 16px 32px;
        margin-top: 32px;
    }
    .select {
        &_wrap {
            display: flex;
            width: 100%;
            gap: 32px;
            flex-wrap: wrap;
        }
        &__item {
            width: calc((100% / 4) - (32px * 3 / 4));
            @media (max-width: 1023px) {
                width: calc((100% / 2) - (32px / 2));
            }
            @media (max-width: 767px) {
                width: 100%;
            }
            label {
                color: #2173ba;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                opacity: 0.5;
                margin-bottom: 10px;
                display: block;
            }
        }
    }
}

.text-page {
    position: relative;
    z-index: 1;
    margin-bottom: 180px;
    @media (max-width: 1024px) {
        margin-bottom: 500px;
    }
    @media (max-width: 767px) {
        margin-bottom: 0;
    }
    &__container {
      padding-top: 3vw;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        @media (max-width: 1023px) {
            flex-wrap: wrap;
        }
        @media (max-width: 767px) {
            padding-bottom: 500px;
        }
    }
    &__left {
        width: 40%;
        min-width: 40%;
        margin-right: 32px;
        @media (max-width: 1023px) {
            width: 100%;
            margin-right: 0;
        }
    }
    &__right {
        width: calc(60% - 72px);
        position: relative;
        @media (max-width: 1023px) {
            position: relative;
            width: calc(100% + 40px);
            margin-left: -20px;
            margin-right: -20px;
            transform: none;
            right: 0;
        }
        &_mobile {
            display: none;
            @media (max-width: 1023px) {
                display: flex;
                width: 100%;
                margin-bottom: 32px;
            }
            &>img {
                display: block;
                width: 100%;
            }
        }
        &_wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: absolute;
            @media (max-width: 1023px) {
                margin-top: 0;
                position: relative;
                transform: none;
            }
            &>img {
                width: 100%;
                display: block;
                max-height: 480px;
                object-fit: cover;
                @media (max-width: 1023px) {
                    display: none;
                }
            }
        }
    }
    &__links {
        @media (max-width: 1023px) {
            margin-left: 16px;
            margin-right: 16px;
        }
        &_title {
            color: #2173ba;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
        }
        &_wrap {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
        }
        &_item {
            display: flex;
            align-items: center;
            text-decoration: none;
            overflow: hidden;
            color: #2173ba;
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            @media (max-width: 767px) {
                width: 100%;
            }
            img {
                width: 40px;
            }
        }
    }
    &__bottom {
        width: 100%;
        margin-top: 120px;
        background-image: url('./../../assets/images/content/noreg_bck.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: 1023px) {
            margin-top: 0;
        }
        @media (max-width: 767px) {
            background-image: url('./../../assets/images/content/noreg_bck_m.png');
        }
    }
    &__noreg {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 32px;
        gap: 24px;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
        span {
            color: #ffffff;
            font-size: 28px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            display: block;
            display: block;
            @media (max-width: 767px) {
                font-size: 16px;
            }
        }
        a {
            display: block;
        }
        .button {
            background: #ffffff;
            color: #da1f3d;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
            width: 260px;
            border-radius: 2px;
        }
    }
    &__description {
        &_item {
            margin-bottom: 16px;
        }
    }
    &__label {
        color: #2173ba;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 8px;
    }
    &__text {
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
    &__likes {
        &_wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        img {
            width: 18px;
            height: 18px;
        }
        .like_text {
            color: #000;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            margin-left: 7px;
        }
    }
    &__btn {
        background: linear-gradient(180deg, #f15a22 0%, #da1f3d 100%);
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        height: 64px;
        width: 240px;
        display: flex;
        padding: 0;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        margin-left: 32px;
        &.voted {
            background: #d9f6ff;
            color: #6d7b80;
        }
    }
    &__hidden {
        color: #da1f3d;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
    }
}

.pagination {
    display: flex;
    position: relative;
    gap: 14px;
    width: calc(100% - 100px);
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    &__wrap {
        position: relative;
    }
    a[role='button'],
    &__link {
        display: flex;
        height: 48px;
        width: 48px;
        color: #2173ba;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        &:hover {
            background: #f1faff;
        }
    }
    li.selected {
        a {
            background: #f1faff;
        }
    }
    .previous,
    .next {
        display: none;
    }
    &__link {
        position: absolute;
        top: 0;
        background: #f1faff;
        &.next {
            right: 0;
        }
        &.previous {
            right: 52px;
        }
        &.disable {
            opacity: 0.2;
        }
    }
}

.react-date-picker__calendar {
    z-index: 9 !important;
}

.submit_modal {
    h2 {
        color: #ffffff;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        text-transform: uppercase;
    }
    hr {
        margin: 0;
        margin-top: 32px;
        margin-bottom: 32px;
        background: #00c2ff;
        border: 0;
        height: 1px;
        width: 100%;
    }
    p {
        color: #ffffff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
    }
    &_text {
        &_wrap {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .like_text {
                color: #ffffff;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
    &_btn {
        &_wrap {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 32px;
            .modal__btn {
                max-width: none;
                width: auto;
                border-radius: 2px;
                &-red {
                    padding: 14px 32px;
                    background: linear-gradient(180deg, #f15a22 0%, #da1f3d 100%);
                    border: 0;
                }
                &-white {
                    padding: 12px 32px;
                    background: transparent;
                    border: 1px solid #fff;
                }
            }
        }
    }
}

.addres-text {

  &__item {  display: flex;
    gap: 10px;
  color: white}
}
