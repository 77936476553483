.topsimple {
    background-image: url('./../assets/images/top_clean.png');
    background-repeat: no-repeat;
    background-size: cover;
    .mobile {
        display: none !important;
        @media (max-width: 1023px) {
            display: block !important;
        }
    }
    &__bg {
        padding-bottom: calc(100vw / 8);
        @media (max-width: 1023px) {
            padding-bottom: calc(100vw / 3);
            background-position: 28% bottom;
        }
    }
    &__container {
        padding-top: 100px;
    }
    &__title {
        color: #ffffff;
        font-size: 48px;
        font-style: normal;
        font-weight: 800;
        line-height: 100%;
        letter-spacing: 5.04px;
        text-transform: uppercase;
        margin-top: 32px;
        @media (max-width: 1023px) {
            font-size: 36px;
        }
        @media (max-width: 767px) {
            font-size: 24px;
        }
    }
    &__caption {
        color: #fff;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-top: 32px;
        max-width: 40%;
        @media (max-width: 1023px) {
            max-width: 100%;
            margin-top: 24px;
            font-size: 16px;
        }
        @media (max-width: 767px) {
            font-size: 14px;
        }
    }
    &__nomination {
      display: flex;
      color: #fff;
      & span {
        margin: 0 10px;
      }
      margin-top: 32px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      text-transform: uppercase;
      @media (max-width: 1023px) {
      flex-direction: column;
      & span {
        display: none;
      }
    }
      
    }
    &__text {
        color: #ffffff;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 32px;
        max-width: 1000px;
        text-transform: uppercase;
        @media (max-width: 1023px) {
            max-width: 100%;
            margin-top: 24px;
            font-size: 16px;
        }
        &.short {
            max-width: 80%;
            @media (max-width: 1023px) {
                max-width: 100%;
            }
        }
    }
}
