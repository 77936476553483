.bottom {
  margin-bottom: 50px;
  background-image: url('./../assets/images/content/bottom/bottom_bck.png');
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  &__wrap {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 32px;
    @media (max-width: 1023px) {
      flex-direction: column;
    }
  }
  &__form {
    position: relative;
    width: 50%;
    background-image: url('./../assets/images/content/bottom/form_bck.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    padding: 32px 32px 170px;
    @media (max-width: 1023px) {
      width: 100%;
      padding: 32px;
    }
    &_dot {
      position: absolute;
      right: -16px;
      top: -16px;
    }
    &_title {
      color: #ffffff;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.4px;
      text-transform: uppercase;
    }
    .callback {
      &__input {
        &-group {
          margin-top: 32px;
          display: flex;
          align-items: flex-start;
          gap: 32px;
          flex-wrap: wrap;
        }
        &-block {
          position: relative;
          display: flex;
          flex-direction: column;
          width: calc(50% - 16px);
          @media (max-width: 767px) {
            width: 100%;
          }
          &.w-full {
            width: 100%;
          }
          label {
            color: #ffffff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            opacity: 0.5;
            margin-bottom: 10px;
            display: block;
          }
          input,
          textarea {
            border-radius: 2px;
            border: 1px solid var(--White, #fff);
            background: rgba(0, 0, 0, 0.5);
            padding: 14px 16px;
            color: #ffffff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            width: 100%;
            &::placeholder {
              color: #ffffff;
              opacity: 0.5;
            }
          }
          textarea {
            resize: none;
            height: 100px;
          }
        }
      }
      &__btn {
        &-submit {
          position: relative;
          width: 272px;
          min-width: 272px;
          padding: 26px 32px;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          @media (max-width: 480px) {
            min-width: auto;
            width: 100%;
          }
        }
      }
      &__descr {
        display: inline-block;
        margin-left: auto;
        margin-right: 0;
        color: rgba(255, 255, 255, 0.7);
        text-align: right;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        align-self: center;
        width: calc(100% - 310px);
        @media (max-width: 1279px) {
          width: auto;
          order: 10;
          text-align: left;
          margin-right: auto;
          margin-left: 0;
        }
        a {
          color: rgba(255, 255, 255, 1);
          text-decoration: none;
        }
      }
      &__error {
        color: #ff0000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        opacity: 0.7;
        position: absolute;
        bottom: -18px;
        left: 2px;
        white-space: nowrap;
      }
    }
  }
  &__vote {
    position: relative;
    width: 50%;
    background-image: url('./../assets/images/content/bottom/vote_bck.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 80%;
    padding: 32px 160px 32px 32px;
    margin-bottom: 300px;
    // max-height: 400px;
    
    @media (max-width: 1023px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      margin-bottom: 100px;
    }
    @media (max-width: 480px) {
      width: 100%;
      padding: 32px;
    }
    &_dot {
      position: absolute;
      right: -16px;
      top: -16px;
    }
    &_title {
      color: #ffffff;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: 1.6px;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 24px;
      }
    }
    &_descr {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
      margin-top: 24px;
    }
    &_text {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      text-transform: uppercase;
      margin-top: 24px;
    }
  }
  &__btn-flex {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    @media (max-width: 1023px) {
      margin-top: 24px;
      margin-bottom: 0;
    }
    .button {
      color: #da1f3d;
      border: 1px solid #da1f3d;
      background: #ffffff;
      &:hover {
        color: #ffffff;
        border: 1px solid #ffffff;
        background: #da1f3d;
      }
    }
    .button_white {
      color: #ffffff;
      border: 1px solid #ffffff;
      background: transparent;
      &:hover {
        color: #da1f3d;
        border: 1px solid #da1f3d;
        background: #ffffff;
      }
    }
  }
}
