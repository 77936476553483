.votes {
  margin-bottom: 80px;
  background-image: url('./../../assets/images/content/votes/votes_bck.png');
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  @media (max-width: 767px) {
    background-image: url('./../../assets/images/content/votes/votes_bck_m.png');
  }
  &__title {
    color: #ffffff;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    @media (max-width: 1279px) {
      flex-direction: column;
    }
  }
  &__left {
    width: 75%;
    padding: 48px 32px;
    display: flex;
    flex-direction: column;
    position: relative;
    @media (max-width: 1279px) {
      padding: 36px 16px;
      width: 100%;
    }
    @media (max-width: 767px) {
      padding: 36px 0;
    }
    &_top {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  &__menu {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    @media (max-width: 767px) {
      margin-top: 24px;
      align-self: flex-end;
    }
    &_item {
      color: #ffffff;
      text-align: center;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding: 0 36px;
      border-right: 1px solid #ffffff;
      cursor: pointer;
      &:last-child {
        border-right: 0;
      }
      @media (max-width: 767px) {
        padding: 0 16px;
        font-size: 14px;
      }
      &.active {
        text-decoration: underline;
      }
    }
  }
  &__list {
    margin-top: 32px;
    margin-bottom: 32px;
    display: flex;
    gap: 32px;
    justify-content: flex-start;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  }
  &__empty {
    max-width: 560px;
    text-align: center;
    color: #ffffff;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 auto;
    display: flex;
    align-items: center;
    border: 1px solid #ffffff;
    border-radius: 2px;
    background: rgb(255, 255, 255, 0.1);
    padding: 16px 32px;
    margin-top: 32px;
  }
  &__item {
    width: calc((100% / 3) - (32px * 2 / 3));
    text-decoration: none;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
      width: 100%;
    }
    &_img {
      width: 100%;
      img {
        width: 100%;
        height: 230px;
        object-fit: cover;
        object-position: center;
        display: block;
      }
    }
    &_text {
      background: #ffffff;
      padding: 16px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    &_title {
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-transform: uppercase;
    }
    &_subtitle {
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 16px;
    }
    &_descr {
      overflow: hidden;
      color: #000;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 16px;
    }
    &_like {
      margin-top: auto;
      padding-top: 16px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      color: #000;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      img {
        margin-left: 7px;
        margin-bottom: 2px;
        display: block;
      }
    }
  }
  &__right {
    width: 25%;
    position: relative;
    padding: 48px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1279px) {
      width: 100%;
    }
    &::before {
      content: '';
      position: absolute;
      background: rgba(0, 0, 0, 0.3);
      width: 100vw;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
      @media (max-width: 1279px) {
        left: -50%;
        width: 200vw;
      }
    }
    .title {
      text-align: center;
      max-width: 280px;
    }
  }
  &__rules {
    &_img {
      margin-top: 32px;
      margin-bottom: 32px;
      width: 100%;
      position: relative;
      z-index: 1;
      @media (max-width: 1279px) {
        max-width: 260px;
      }
      img {
        width: 100%;
      }
    }
  }
  &__button {
    position: relative;
    z-index: 1;
    border-radius: 2px;
    border: 1px solid #fff;
    padding: 26px 32px;
    margin: 0 auto;
    display: block;
    width: 200px;
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: auto;
  }
}
