.steps {
  margin-top: 42px;
  margin-bottom: 120px;
  &__title {
    color: #da1f3d;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 48px;
    @media (max-width: 767px) {
      font-size: 24px;
      letter-spacing: 1.2px;
    }
  }
  &__wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    width: calc(25% - (32px * 3 / 4));
    background-image: url('./../assets/images/content/steps/nomination.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 24px 16px 130px;
    position: relative;
    &:nth-child(2n) {
      background-image: url('./../assets/images/content/steps/nomination_a.png');
    }
    @media (max-width: 1023px) {
      width: 100%;
      max-width: 565px;
      background-image: url('./../assets/images/content/steps/nomination_m.png');
      background-position: right;
      background-size: auto;
      padding: 30px 126px 60px 20px;
      &:nth-child(2n) {
        background-image: url('./../assets/images/content/steps/nomination_m.png');
      }
    }
    &_text {
      overflow: hidden;
      color: #ffffff;
      text-align: center;
      text-overflow: ellipsis;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @media (max-width: 1023px) {
        text-align: left;
      }
    }
    &_desc {
      color: #ffffff;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-top: 24px;
      @media (max-width: 1023px) {
        text-align: left;
      }
    }
    &_dot {
      position: absolute;
      width: 100%;
      height: 16px;
      bottom: -40px;
      left: 0;
      @media (max-width: 1023px) {
        width: 16px;
        height: 100%;
        top: 0;
        bottom: auto;
        left: auto;
        right: 0;
      }
      &::before {
        content: '';
        position: absolute;
        width: 16px;
        height: 16px;
        background-color: #ffffff;
        border: 4px solid #da1f3d;
        border-radius: 16px;
        bottom: 0;
        left: calc(50% - 8px);
        z-index: 1;
        @media (max-width: 1023px) {
          bottom: auto;
          top: calc(50% - 8px);
        }
      }
      &_start::after {
        content: '';
        position: absolute;
        width: calc(50% + 32px);
        height: 4px;
        background-color: #da1f3d;
        top: 6px;
        left: 50%;
        z-index: 0;
        @media (max-width: 1023px) {
          width: 4px;
          height: calc(50% + 32px);
          top: 50%;
          left: 6px;
        }
      }
      &_stop::after {
        content: '';
        position: absolute;
        width: calc(50% + 32px);
        height: 4px;
        background-color: #da1f3d;
        top: 6px;
        right: 50%;
        z-index: 0;
        @media (max-width: 1023px) {
          width: 4px;
          height: calc(50% + 32px);
          top: auto;
          bottom: 50%;
          left: 6px;
        }
      }
      &_middle::after {
        content: '';
        position: absolute;
        width: calc(100% + 32px);
        height: 4px;
        background-color: #da1f3d;
        top: 6px;
        left: -16px;
        z-index: 0;
        @media (max-width: 1023px) {
          width: 4px;
          height: calc(100% + 32px);
          top: -16px;
          left: 6px;
        }
      }
    }
  }
}
