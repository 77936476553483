.nominations {
  margin-top: 42px;
  margin-bottom: 80px;
  &__title {
    color: #da1f3d;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 32px;
    @media (max-width: 767px) {
      font-size: 24px;
      letter-spacing: 1.2px;
    }
  }
  &__wrap {
    width: 100%;
    display: flex;
    gap: 32px;
    margin-bottom: 32px;
    @media (max-width: 1023px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__item {
    box-sizing: border-box;
    width: calc((100% / 3) - (32px * 2 / 3) + 15px);
    margin-right: -15px;
    background-image: url('./../assets/images/content/nominations/bck.png');
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 110px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 1023px) {
      width: calc(100% + 15px);
      margin-right: -15px;
      max-width: 559px;
    }
    &_title {
      color: #ffffff;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 24px;
    }
    &_desc {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      margin-bottom: 24px;
    }
  }
}

.position {
  &__wrap {
    width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 32px;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
}
  &__item {
    max-width: 189px;
    width: 100%;
    background: url('./../assets/images/pos_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 46px 12px;
    @media (max-width: 1023px) {
      max-width: 559px;
    }
    &_desc {
      color: #ffffff;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      display: flex;
      align-self: center;
    }
  }
}
