.footer {
  padding-top: 32px;
  padding-bottom: 64px;
  background: #ffffff;
  z-index: 1;
  bottom: 0;
  width: 100%;
  position: relative;
  @media (max-width: 767px) {
    padding-top: 0;
  }
  &.simple {
    .footer__border {
      @media (min-width: 1024px) {
        display: none;
      }
    }
  }
  .mobile {
    display: none !important;
    @media (max-width: 1023px) {
      display: block !important;
    }
  }
  .logo_link {
    @media (max-width: 1023px) {
      order: 1;
      margin-bottom: 30px;
    }
  }
  &__border {
    position: absolute;
    top: calc((100vw / 7) * -1);
    z-index: -1;
    width: 100%;
    @media (max-width: 767px) {
      bottom: 0;
      top: calc((100vw / 5) * -1);
    }
    @media (max-width: 767px) {
      bottom: 0;
      top: calc((100vw / 5) * -1);
    }
    &_img {
      width: 100%;
      @media (max-width: 1023px) {
        object-fit: cover;
        height: 200px;
        object-position: 66% 0;
      }
    }
  }
  &__container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      margin-top: -30px;
    }
  }
  &__social-list {
    display: flex;
    margin-right: 30px;
    margin-left: 30px;
    @media (max-width: 1023px) {
      order: 2;
      margin-bottom: 30px;
    }
  }
  &__social-item {
    display: flex;
    line-height: 0;
    margin-right: 8px;
    transition: all 0.35s;
    &:hover {
      opacity: 0.7;
    }
  }
  &__text {
    font-family: 'Manrope', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    padding-left: 30px;
    padding-right: 30px;
    a {
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    @media (max-width: 1023px) {
      order: 5;
      padding: 0;
      margin-bottom: 30px;
      width: 100%;
    }
  }
  &__desc {
    font-family: 'Manrope', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #828282;
    max-width: 25%;
    @media (max-width: 1023px) {
      order: 6;
      max-width: 100%;
      width: 100%;
    }
  }
  &__rules {
    text-decoration: none;
    color: #00529c;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    white-space: nowrap;
    display: flex;
    height: 32px;
    align-items: center;
    padding-top: 4px;
    @media (max-width: 1023px) {
      order: 3;
      margin-bottom: 30px;
    }
  }
  &__btns {
    display: flex;
    align-items: flex-end;
    @media (max-width: 1023px) {
      order: 4;
      width: 100%;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    margin-left: 32px;
    padding: 0;
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: #00529c;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.35s;
    &:hover {
      opacity: 0.7;
    }
    @media (max-width: 1023px) {
      display: none;
    }
  }
  &__arrow-up {
    margin-left: 8px;
  }
}
