.comitet {
    margin-top: 42px;
    margin-bottom: 80px;
    &__title {
        color: #da1f3d;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            font-size: 24px;
            letter-spacing: 1.2px;
        }
    }
    &__wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 32px;
    }
    &__item {
        display: flex;
        flex-direction: column;
        width: calc((100% / 6) - (32px * 5 / 6));
        position: relative;
        @media (max-width: 1023px) {
            width: calc(50% - (32px / 2));
        }
        @media (max-width: 767px) {
            width: 100%;
        }
        &_img {
            max-width: 200px;
            @media (max-width: 767px) {
                max-width: 320px;
            }
        }
        &_lastname {
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin-top: 16px;
        }
        &_name {
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-top: 4px;
            height: 40px;
        }
        &_desc {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            width: 80%;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            padding-right: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
        }
    }
}
